import * as React from "react"
// import banner from "../images/newDesign/banner2.jpg"banerMIssion
import banner from "../images/newDesign/pexels-maria-orlova-4946702.jpg"
import Carousel from "react-multi-carousel"
import OurTeam from "../pages/our-team"
import { Link } from "gatsby"
import teamData from "../assets/data/teams.json"

export default function AboutIndianESGNetwork() {
  let teamArrayData = teamData.data

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  return (
    <>
      <div className="container-custom">
        <Carousel
          className="carousal-custom"
          responsive={responsive}
          itemClass="carousel-item-padding-40-px"
          swipeable={true}
          draggable={false}
          showDots={false}
          infinite={false}
          autoPlay={false}
          arrows={false}
        >
          <div>
            <div className="banner-btn-parent">
              <div className="heading-hero-mission">OUR MISSION</div>
              <div className="para-hero-mission mt-lg-4 mt-md-4">
                Catalyst for India's ESG Conscious March from @75 years to @100
                years of Independence
                <br />
                <br />
                Ideating and creating an implementation support network for
                Government, Corporates, Foundations and Social Organizations in
                order to achieve the Sustainable Development Goals (SDGs)
              </div>
            </div>
            <div>
              <img src={banner} alt={`Item`} />
            </div>
          </div>
        </Carousel>

        <div>
          {/* ===============About Us============ */}
          <div className="mt-5 pt-5 px-0 px-md-5 px-lg-5 px-xl-5">
            <div className="row align-items-center">
              <div className="col-md-6 mb-4 order-last order-sm-last order-md-first order-lg-first order-xxl-first">
                <h1 className="common-heading mb-3">ABOUT US</h1>

                <div align="justify" className="comman-para">
                  The realm of Environmental, Social, and Governance (ESG) is of
                  utmost importance in promoting future growth and
                  sustainability, not only in India but also worldwide.
                  <br />
                  <br /> The Indian ESG Network, established in collaboration
                  with our partner organizations, aims to collaborate with
                  government bodies, corporations, social organizations, and ESG
                  facilitators to expedite the ESG progress in India and beyond.
                </div>
                <div className="col-md-12 order-first order-sm-first order-md-last order-lg-last order-xxl-last">
                  <Link to="/about-indian-esg-network">
                    <button className="btn learn-more-btn mt-4">
                      LEARN MORE
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <iframe
                  width="100%"
                  height="315"
                  src="https://www.youtube.com/embed/qccJZVLWWwg"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="container-fluid mt-5 pt-5 px-0 px-md-5 px-lg-5 px-xl-5">
            <h1 className="common-heading mb-3">OUR PARTNERS</h1>
            <div className="row">
              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
                style={{
                  backgroundColor: "#f7f4e7",
                }}
              >
                <div class="card border-0 p-2 bg-transparent">
                  <div class="card-body">
                    <h5 class="card-title fw-bold h2 heading">
                      Government Bodies
                    </h5>
                    <p class="card-text comman-para" align="justify">
                      Lead the implementation of ESG and SDG implementation
                      goals, Facilitate and Chair the Indian ESG Network Events
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
                style={{
                  backgroundColor: "#f7f4e7",
                }}
              >
                <div class="card border-0 p-2 bg-transparent">
                  <div class="card-body">
                    <h5 class="card-title fw-bold h2 heading">
                      Academic Institutions
                    </h5>
                    <p class="card-text comman-para" align="justify">
                      Knowledge Partners for Indian ESG Network Events, Training
                      Programs, Key Faculty Members for the Workshops.
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
                style={{
                  backgroundColor: "#f7f4e7",
                }}
              >
                <div class="card border-0 p-2 bg-transparent">
                  <div class="card-body">
                    <h5 class="card-title fw-bold h2 heading">
                      Corporates/ MSME
                    </h5>
                    <p class="card-text comman-para" align="justify">
                      Key Stakeholders for ESG Orientation Programs, Training
                      Programs and Workshops on various aspects of ESG for
                      employees and management team.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-3 border"
                style={{
                  backgroundColor: "#f7f4e7",
                }}
              >
                <div class="card border-0 p-2 bg-transparent">
                  <div class="card-body">
                    <h5 class="card-title fw-bold h2 heading">
                      Social Enterprises
                    </h5>
                    <p class="card-text comman-para" align="justify">
                      Training, Orientations on various aspects of ESG - Social
                      Impact Assessment and Social Audit Frameworks for
                      employees and management.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OurTeam />
      </div>
    </>
  )
}
